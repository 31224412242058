<template>
  <v-container fluid>
    <v-card-title>
      대시보드
      <v-spacer />
      <a :href="graphqlUri + '/userData'" target="_blank" style="text-decoration: none">
        <v-btn text depressed color="deep-purple accent-2" dark class="pa-1">
          <v-icon>mdi-download</v-icon>
          User Data
        </v-btn>
      </a>
    </v-card-title>
    <v-row dense>
      <v-col v-for="card in cards" :key="card.title" :cols="$vuetify.breakpoint.xs ? 12 : 4">
        <v-card class="mx-auto" outlined>
          <v-card-text>
            <div>{{ card.subTitle }}</div>
            <p class="display-1 text--primary">{{ card.title }}</p>
            <div v-if="card.text" class="subtitle-1 text--primary">
              {{ card.text }}
            </div>
          </v-card-text>

          <Chart v-if="card.line" type="line" :data="card.line" height="300px" :options="options" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, toRefs } from '@vue/composition-api'
import Chart from '@/components/Chart.vue'

export default defineComponent({
  components: { Chart },

  setup(props, { root }) {
    const state = reactive({
      graphqlUri: process.env.VUE_APP_BASE_URL,
      cards: [],
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    })

    onBeforeMount(async () => {
      const data = await root.$store.dispatch('app/getDashBoard')
      state.cards = [
        {
          title: '유저 가입',
          subTitle: '지난 주 대비',
          text: `${Math.abs(data[0].totalUser - data[1].totalUser)}명${
            data[0].totalUser - data[1].totalUser > 0 ? ' 상승했어요! 🔥' : data[0].totalUser !== data[1].totalUser ? ' 하락했어요! 😭' : '이에요! 😭'
          }`,
        },
        {
          title: '활성 유저',
          subTitle: '이번 주',
          text: `${data[0].WAU}명이에요! 🧐`,
        },
        {
          title: '비활성 유저',
          subTitle: '이번 주',
          text: `${data[0].noActionUser}명이에요! 😢`,
        },
        {
          title: '질문만 유저',
          subTitle: '주별',
          line: {
            labels: ['4주전', '3주전', '2주전', '지난주', '이번주'],
            datasets: [
              {
                label: '질문만 유저',
                fill: true,
                backgroundColor: '#ffd600',
                data: [data[4].onlyQuestionUser, data[3].onlyQuestionUser, data[2].onlyQuestionUser, data[1].onlyQuestionUser, data[0].onlyQuestionUser],
              },
            ],
          },
        },
        {
          title: '칼럼만 유저',
          subTitle: '주별',
          line: {
            labels: ['4주전', '3주전', '2주전', '지난주', '이번주'],
            datasets: [
              {
                label: '칼럼만 유저',
                fill: true,
                backgroundColor: '#ffd600',
                data: [data[4].onlyColumnUser, data[3].onlyColumnUser, data[2].onlyColumnUser, data[1].onlyColumnUser, data[0].onlyColumnUser],
              },
            ],
          },
        },
        {
          title: '질문 및 칼럼 유저',
          subTitle: '주별',
          line: {
            labels: ['4주전', '3주전', '2주전', '지난주', '이번주'],
            datasets: [
              {
                label: '질문 및 칼럼 유저',
                fill: true,
                backgroundColor: '#ffd600',
                data: [data[4].bothUser, data[3].bothUser, data[2].bothUser, data[1].bothUser, data[0].bothUser],
              },
            ],
          },
        },
      ]
    })

    return {
      ...toRefs(state),
    }
  },
})
</script>
