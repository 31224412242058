<template>
  <canvas ref="chart" :width="width" :height="height" />
</template>

<script>
import { defineComponent, onMounted, reactive, watch, toRefs } from '@vue/composition-api'
import Chart from 'chart.js/auto'

export default defineComponent({
  name: 'Chart',

  props: {
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    type: {
      type: String,
    },
    data: {
      type: Object,
    },
    options: {
      type: Object,
    },
  },

  setup(props) {
    const state = reactive({
      chart: null,
      ctx: null,
    })

    const chart = () => {
      if (state.ctx) state.ctx.destroy()

      const ctx = state.chart.getContext('2d')

      state.ctx = new Chart(ctx, {
        type: props.type,
        data: props.data,
        options: props.options,
      })
    }

    watch(() => props.data, chart)

    onMounted(() => {
      chart()
    })

    return {
      ...toRefs(state),
    }
  },
})
</script>
